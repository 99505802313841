import React from 'react';
import {VideoSection,VideoCol,VideoColHeading,VideoColItem,VideoPlay} from './video.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';

const Video =()=>{
    const JSONData  = useStaticQuery(graphql`
        query {
            restaurantJson{
                    Video{
                        VideoColHeading
                    }
                }
            }
        `);
    return (
            <VideoSection>
                <Container>
                    <Row>
                        <Col md="12">
                            <VideoCol>
                                <VideoColItem>
                                    <VideoColHeading>{JSONData.restaurantJson.Video.VideoColHeading}</VideoColHeading>
                                    <VideoPlay/>
                                </VideoColItem>
                            </VideoCol>
                        </Col>
                    </Row>
                </Container>
            </VideoSection>
        )
    }
export default Video;