import React from 'react';
import {AboutSection,AboutRightCol,AboutRightColHeading,
    AboutLeftCol,AboutRightPara,AboutCustomRow} from './about.style';
import {CommonBtn} from '../Common/common.style'
import {Container, Col} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const About =()=>{
    const JSONData  = useStaticQuery(graphql`
    query {
        restaurantJson{
                About{
                    AboutLeftImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    
                }
                    AboutRightColHeading
                    AboutRightPara
                    CommonBtnLabel
                }
            }
        }
  `);
    return (
            <AboutSection id="aboutContainer">
                <Container>
                    <AboutCustomRow>
                        <Col md="5">
                            <AboutLeftCol>
                                <GatsImg 
                                    fluid={JSONData.restaurantJson.About.AboutLeftImage.childImageSharp.fluid} 
                                    className="AboutLeftImage"
                                    alt=""
                                />
                            </AboutLeftCol>
                        </Col>
                        <Col md="7">
                            <AboutRightCol>
                                <AboutRightColHeading>{JSONData.restaurantJson.About.AboutRightColHeading}</AboutRightColHeading>
                                <AboutRightPara>{JSONData.restaurantJson.About.AboutRightPara}</AboutRightPara>
                                <CommonBtn background="#dbdbdb" buttonText={JSONData.restaurantJson.About.CommonBtnLabel} link="#">{JSONData.restaurantJson.About.CommonBtnLabel}</CommonBtn>
                            </AboutRightCol>
                        </Col>
                    </AboutCustomRow>
                </Container>
            </AboutSection>
        )
    }
export default About;