import React from 'react';
import {FeaturesSection,FeaturesHeadingWrapper,FeaturesListWrapper,
    FeaturesRightCol,FeaturesContent,FeaturesContentTitle,FeaturesContentPara,FeaturesContentPrice} 
    from './featuredItems.style';
import {SectionHeading} from '../Common/common.style'
import {Container, Row, Col} from '@bootstrap-styled/v4';
import {useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Features =()=>{
    const JSONData  = useStaticQuery(graphql`
        query {
            restaurantJson{
                    Features{
                        SectionHeading
                        FeaturesListWrapper{
                            FeaturesLeftImg{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            FeaturesContentTitle
                            FeaturesContentPara
                            FeaturesContentPrice
                        }
                    }
                }
            }
    `);

    return (
            <FeaturesSection id="featuresContainer">
                <Container>
                    <Row>
                        <Col md="12">
                            <FeaturesHeadingWrapper>
                                <SectionHeading>{JSONData.restaurantJson.Features.SectionHeading}</SectionHeading>
                            </FeaturesHeadingWrapper>
                        </Col>
                    </Row>

                    <FeaturesListWrapper>
                        <Row>
                        {
                            JSONData.restaurantJson.Features.FeaturesListWrapper.map((item,idx) => {
                            return <Col md="12" lg="6">
                                <FeaturesRightCol>
                                    <GatsImg 
                                        fluid={item.FeaturesLeftImg.childImageSharp.fluid} 
                                        className="FeaturesLeftImg"
                                        alt=""
                                    />
                                    <FeaturesContent>
                                        <FeaturesContentTitle>
                                            {item.FeaturesContentTitle}
                                        </FeaturesContentTitle>
                                        <FeaturesContentPara>
                                            {item.FeaturesContentPara}
                                        </FeaturesContentPara>
                                        <FeaturesContentPrice>
                                            {item.FeaturesContentPrice}
                                        </FeaturesContentPrice>
                                    </FeaturesContent>
                                </FeaturesRightCol>
                            </Col>
                            })
                        }
                        </Row>
                    </FeaturesListWrapper>
                </Container>
            </FeaturesSection>
        )
    }
export default Features;