import React from 'react';
import {About2Section,About2RightCol,About2RightColHeading,
    About2LeftCol,About2RightPara,About2CustomRow} from './about2.style';
import {CommonBtn} from '../Common/common.style'
import {Container, Col} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const About2 =()=>{
    const JSONData  = useStaticQuery(graphql`
    query {
        restaurantJson{
                About2{
                    About2RightColHeading
                    About2RightPara
                    CommonBtnLabel
                    About2LeftImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
  `);
        return (
            <About2Section>
                <Container>
                    <About2CustomRow>
                        <Col md="7">
                            <About2RightCol>
                                <About2RightColHeading>{JSONData.restaurantJson.About2.About2RightColHeading}</About2RightColHeading>
                                <About2RightPara>{JSONData.restaurantJson.About2.About2RightPara}</About2RightPara>
                                <CommonBtn background="#dbdbdb" buttonText={JSONData.restaurantJson.About2.CommonBtnLabel} link="#">{JSONData.restaurantJson.About2.CommonBtnLabel}</CommonBtn>
                            </About2RightCol>
                        </Col>
                        <Col md="5">
                            <About2LeftCol>
                                <GatsImg 
                                    fluid={JSONData.restaurantJson.About2.About2LeftImage.childImageSharp.fluid} 
                                    className="About2LeftImage"
                                    alt=""
                                />
                            </About2LeftCol>
                        </Col>
                    </About2CustomRow>
                </Container>
            </About2Section>
        )
    }
export default About2;